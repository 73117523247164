// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-gallery-jsx": () => import("./../../../src/templates/gallery.jsx" /* webpackChunkName: "component---src-templates-gallery-jsx" */),
  "component---src-templates-image-jsx": () => import("./../../../src/templates/image.jsx" /* webpackChunkName: "component---src-templates-image-jsx" */),
  "component---src-templates-project-jsx": () => import("./../../../src/templates/project.jsx" /* webpackChunkName: "component---src-templates-project-jsx" */)
}

